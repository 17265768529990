import React, { createContext, useEffect, useRef, useState } from 'react';
import { Autocomplete, DrawingManager, GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import deleteIcon from '../assets/imagotipo.png';
import { GuardarPolygono, ObtenerSectores } from '../helpers/SectoresHelper';
import { TablaSectoresComponent } from '../components/TablaSectoresComponent';
import { ModalSectorComponent } from '../components/ModalSectorComponent';
import { Button } from '@mui/material';
import { ModalEliminaSector } from '../components/ModalEliminaSector';

const libraries = ['places', 'drawing'];

export const SectoresContext = createContext();

export const SectoresPage = () => {
    const [sectorEditando, setSectorEditando] = useState();
    const [modalSector, setModalSector] = useState(false);
    const [modalEliminaSector, setModalEliminaSector] = useState(false);
    const [sectorElimina, setSectorElimina] = useState();
    const mapRef = useRef();
    const polygonRefs = useRef([]);
    const activePolygonIndex = useRef();
    const autocompleteRef = useRef();
    const drawingManagerRef = useRef();

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyDH9WzJ1Gkjg7VsxW6aSp9v2QJpgx0GVWI',
        libraries
    });

   
    

    const [polygons, setPolygons] = useState();

    useEffect(() => {
        obtenerSectores();
    }, [])

    const obtenerSectores = () => {
        ObtenerSectores().then(response => {
            console.log('polygonos obtenidos', response)
            setPolygons(response);
        })
    }
    

    // useEffect(() => {
    //     console.log(JSON.stringify(polygons[activePolygonIndex],null,1))
    // }, [polygons])

    const defaultCenter = {
        lat: 31.72061703693808,
        lng: -106.4328776223116,
    }
    const [center, setCenter] = useState(defaultCenter);

    const containerStyle = {
        width: '100%',
        height: '400px',
    }

    const autocompleteStyle = {
        boxSizing: 'border-box',
        border: '1px solid transparent',
        width: '240px',
        height: '38px',
        padding: '0 12px',
        borderRadius: '3px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
        fontSize: '14px',
        outline: 'none',
        textOverflow: 'ellipses',
        position: 'absolute',
        right: '8%',
        top: '11px',
        marginLeft: '-120px',
    }

    const deleteIconStyle = {
        cursor: 'pointer',
        backgroundImage: `url(${deleteIcon})`,
        height: '24px',
        width: '24px',
        marginTop: '5px', 
        backgroundColor: '#fff',
        position: 'absolute',
        top: "2px",
        left: "52%",
        zIndex: 99999
    }

    const polygonOptions = {
        fillOpacity: 0.3,
        fillColor: '#ff0000',
        strokeColor: '#ff0000',
        strokeWeight: 2,
        draggable: true,
        editable: true
    }

    const drawingManagerOptions = {
        polygonOptions: polygonOptions,
        drawingControl: false,
        drawingControlOptions: {
            position: window.google?.maps?.ControlPosition?.TOP_CENTER,
            drawingModes: [
                window.google?.maps?.drawing?.OverlayType?.POLYGON
            ]
        }
    }

    const onLoadMap = (map) => {
        mapRef.current = map;
    }

    const onLoadPolygon = (polygon, index) => {
        polygonRefs.current[index] = polygon;
    }

    const onClickPolygon = (index) => {
        activePolygonIndex.current = index; 
    }

    const onLoadAutocomplete = (autocomplete) => {
        autocompleteRef.current = autocomplete;
    }

    const onPlaceChanged = () => {
        const { geometry } = autocompleteRef.current.getPlace();
       
        const bounds = new window.google.maps.LatLngBounds();
        if (geometry.viewport) {
            bounds.union(geometry.viewport);
        } else {
            bounds.extend(geometry.location);
        }
        mapRef.current.fitBounds(bounds);
    }

    const onLoadDrawingManager = drawingManager => {
        drawingManagerRef.current = drawingManager;
    }

    const guardarCambios = async() => {
        console.log('Guardar cambios', polygons);
        polygons?.map(poligono => {
            if(poligono.editado) {
                console.log('Guardar', poligono.idSector, poligono.coordenadas);
                const guardar = GuardarPolygono(poligono.idSector, poligono.coordenadas).then(response=>{
                    console.log('guardado');
                })
            }
        })

    }

    const onOverlayComplete = ($overlayEvent) => {
        drawingManagerRef.current.setDrawingMode(null);
        if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
            const newPolygon = $overlayEvent.overlay.getPath()
                .getArray()
                .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }))

            // start and end point should be same for valid geojson
            const startPoint = newPolygon[0];
            newPolygon.push(startPoint);
            $overlayEvent.overlay?.setMap(null);
            setPolygons([...polygons, newPolygon]);
        }
    }

    const onDeleteDrawing = () => {  
        const filtered = polygons.filter((polygon, index) => index !== activePolygonIndex.current) 
        setPolygons(filtered)
    }

    useEffect(() => {
        console.log('cambiaron los poligonos', polygons)
    }, [polygons])
    

    const onEditPolygon = (index) => {

        const polygonRef = polygonRefs.current[index];
        if (polygonRef) {
            const coordinates = polygonRef.getPath()
                .getArray()
                .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));

            const allPolygons = [...polygons];
            console.log('coordinates',coordinates,'poligono editado', sectorEditando, index)

            // GuardarPolygono(index, coordinates).then(response=>{
            //     console.log('respuesta de guardar coordenadas', response);
            //     obtenerSectores();
            // })
            const actualizaPoligonos = polygons?.map(poligono=> {
                if(poligono.idSector==index) {
                    return {...poligono, coordenadas:coordinates, editado:true};
                }
                return poligono;
            })

            //allPolygons[index].coordenadas = coordinates;
            setPolygons(actualizaPoligonos);
        }
    }

    return (
        <SectoresContext.Provider value={{modalSector, setModalSector, modalEliminaSector, setModalEliminaSector, sectorElimina, setSectorElimina, obtenerSectores}} >
        <>
 
        {
        isLoaded
            ?
            (
            <div className='map-container' style={{ position: 'relative' }}>
                <div
                        style={{
                            position:'absolute',
                            zIndex:99999999,
                            display:'flex',
                            flexDirection:'row',
                            flex:1,
                            width:'100%',
                            backgroundColor:'transparent',
                            justifyContent:'center',
                            marginTop:10,
                            marginLeft:0
                        }}
                
                >
                    <Button
                        onClick={() => {guardarCambios()}}
                        variant="contained"
                        style={{
                            backgroundColor:'#642644'
                        }}
                    >
                        Guardar cambios
                    </Button>
                    <Button
                        onClick={() => {obtenerSectores()}}
                        variant="contained"
                        style={{
                            marginLeft:10,
                            backgroundColor:'#642644'
                        }}
                    >
                        Deshacer cambios
                    </Button>
                </div>
                <GoogleMap
                    zoom={15}
                    center={center}
                    onLoad={onLoadMap}
                    mapContainerStyle={containerStyle}
                    onTilesLoaded={() => setCenter(null)}
                >
                    <DrawingManager
                        onLoad={onLoadDrawingManager}
                        onOverlayComplete={onOverlayComplete}
                        options={drawingManagerOptions}
                    />
                    {
                        polygons?.map((iterator, index) => (
                            <Polygon
                                key={iterator.idSector}
                                onLoad={(event) => onLoadPolygon(event, iterator.idSector)}
                                onMouseDown={() => onClickPolygon(iterator.idSector)}
                                onMouseUp={() => { setSectorEditando(iterator.idSector); onEditPolygon(iterator.idSector)}}
                                onDragEnd={() => onEditPolygon(iterator.idSector)}
                                options={{
                                    fillOpacity: 0.3,
                                    fillColor: iterator?.color,
                                    strokeColor: '#ff0000',
                                    strokeWeight: 2,
                                    draggable: true,
                                    editable: true
                                }}
                                paths={iterator.coordenadas}
                                draggable
                                editable
                            />
                        ))
                    }
                    <Autocomplete
                        onLoad={onLoadAutocomplete}
                        onPlaceChanged={onPlaceChanged}
                    >
                        <input
                            type='text'
                            placeholder='Search Location'
                            style={autocompleteStyle}
                        />
                    </Autocomplete>
                </GoogleMap>
                </div>
            ) : null }
        <div>
            <TablaSectoresComponent sectores={polygons}/>
        </div>
            </>
            <ModalSectorComponent />
            <ModalEliminaSector />
            </SectoresContext.Provider>
    );
}